/* You can add global styles to this file, and also import other style files */
@import "./assets/one-customer-styles/main.scss";


// @include custom-mat-mdc-option;

// *,
// *:before,
// *:after {
//     -webkit-box-sizing: inherit;
//     -moz-box-sizing: inherit;
//     box-sizing: inherit;
//     margin: 0;
//     padding: 0;
// }
 
// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@mixin custom-mat-mdc-option {
    .mat-mdc-option {
      .mdc-list-item__primary-text {
        color: #14171A;
      }
    }
  
    .mat-mdc-standard-chip {
      &:not(.mdc-evolution-chip--disabled) {
        .mdc-evolution-chip__text-label {
          color: #14171A !important;
        }
      }
    }
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      color: #14171A !important;
    }
  
    .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after{
      color: #14171A !important;
    }
  }
  
  @include custom-mat-mdc-option;