@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;


@include mat.core();

$my-primary: mat.define-palette(mat.$blue-palette, 800);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-theme: mat.define-light-theme((color: (primary: $my-primary,
        accent: $my-accent,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

$theme: matx.define-theme((color: (theme-type: light,
        primary: matx.$m3-azure-palette,
        tertiary: matx.$m3-blue-palette )));

mat-button-toggle-group {
  @include mat.all-component-themes($theme);

  mat-button-toggle {
    @include mat.all-component-themes($theme);
  }
}

@include mat.all-component-themes($my-theme);